import React from 'react'
import { css } from 'glamor'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import { formatDate, parseDate } from 'react-day-picker/moment'

const CSS = {
  main: css({
    display: 'inline-block',
    ' .DayPicker': {
      fontSize: '14px'
    },
    ' .DayPickerInput > input': css({
      display: 'inline-block',
      width: '160px'
    })
  })
}

const dateForPlaceholder = new Date()

const DayInput = ({ format, ...props }) => (
  <div {...CSS.main}>
    <DayPickerInput
      formatDate={formatDate}
      parseDate={parseDate}
      placeholder={`${formatDate(dateForPlaceholder, format)}`}
      format={format}
      {...props}
    />
  </div>
)

DayInput.propTypes = DayPickerInput.propTypes

DayInput.defaultProps = {
  format: 'MM/DD/YYYY'
}

export default DayInput
