import { css } from 'glamor'

export const CONTAINER_WIDTH = 1080
export const PADDING_WIDTH = 50
export const MID_SCREEN_BREAKPOINT = 1024
export const SMALL_SCREEN_BREAKPOINT = 868
export const SM_SCREEN_BREAKPOINT = 768
export const SMALL_PADDING_WIDTH = 15
export const EXTRA_SMALL_SCREEN_BREAKPOINT = 500

export const container = css({
  width: `${CONTAINER_WIDTH + (PADDING_WIDTH * 2)}px`,
  maxWidth: '100%',
  margin: '0 auto',
  padding: `0 ${PADDING_WIDTH}px`,
  [`@media(max-width: ${SMALL_SCREEN_BREAKPOINT}px)`]: {
    padding: `0 ${SMALL_PADDING_WIDTH}px`
  }
})

export const mediaMinWidth = (minWidth) => `@media(min-width: ${minWidth}px)`
export const mediaMaxWidth = (maxWidth) => `@media(max-width: ${maxWidth}px)`
export const mediaBetweenWidth = (minWidth, maxWidth) => `@media(min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`
