import Cookie from 'cookie'
import Xhr from 'xhr'

const jsonFetch = (options) => {
  return new Promise((resolve, reject) => {
    const cookies = Cookie.parse(window.document.cookie)
    const config = {
      url: options.url,
      method: options.method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      cors: true,
      withCredentials: true
    }

    if (cookies.crumb) {
      config.headers['X-CSRF-Token'] = cookies.crumb
    }

    if (options.data) {
      config.body = JSON.stringify(options.data)
    }

    Xhr(config, (err, response, body) => {
      if (err) {
        return reject(err)
      }

      if (response.statusCode >= 200 && response.statusCode < 300) {
        return resolve(JSON.parse(body))
      } else {
        return reject(JSON.parse(body))
      }
    })
  })
}

export default jsonFetch
