export const baseColors = {
  transparent: 'transparent',
  white: '#FFFFFF',
  blue: '#0054FF',
  darkBlue: '#00154E',
  cyan: '#0DD1FF',
  navy: '#1A31AE',
  black: '#323232',
  grey1: '#757575',
  grey2: '#A5A5A5',
  grey3: '#EBEBEB',
  grey4: '#F9F9F9',
  lightBlue1: '#7AACF4',
  lightBlue2: '#E6EBF4',
  lightBlue3: '#F7FAFF',
  lightBlue4: '#0DD1FF',
  green: '#1BE683',
  red: '#F53059',
  orange: '#FFA31E'
}

export const colors = {
  ...baseColors,
  success: baseColors.green,
  error: baseColors.red,
  background: baseColors.lightBlue3,
  border: baseColors.lightBlue2,
  darkBorder: baseColors.lightBlue1,
  text: baseColors.grey1,
  darkText: baseColors.black
}

export default colors
