import React from 'react'
import Helmet from 'react-helmet'
import { css } from 'glamor'
import '../style/global'
import { fontWeight } from '../style/sizes'
import colors from '../style/colors'
import Container from '../components/container'
import { getInviteLink } from '../api/actions'
import DayInput from '../components/dayInput'
import moment from 'moment'

const CSS = {
  page: css({
    ':before': {
      content: '\'\'',
      position: 'absolute',
      backgroundColor: colors.blue,
      top: 0,
      height: '140px',
      width: '100%',
      zIndex: -1
    }
  }),
  main: css({
    paddingTop: '150px',
    fontSize: '20px',
    fontWeight: fontWeight.light,
    lineHeight: 1.42857143,
    color: colors.darkText,
    minHeight: '80vh',
    ' h1': {
      fontSize: '40px',
      fontWeight: fontWeight.bold,
      lineHeight: '55px',
      maxWidth: '600px',
      marginBottom: '50px'
    },
    ' input': {
      borderRadius: '4px',
      border: `1px solid ${colors.border}`,
      padding: '10px 15px',
      width: '400px',
      maxWidth: '100%',
      '[type="radio"]': {
        width: '50px'
      }
    },
    ' button': {
      border: `1px solid ${colors.border}`,
      backgroundColor: colors.blue,
      color: colors.white,
      padding: '10px 30px',
      borderRadius: '100px',
      transition: 'background-color .2s',
      ':hover': {
        backgroundColor: colors.navy
      }
    }
  }),
  explanation: css({
    marginTop: '10px'
  })
}

const FREE_TRIAL_LENGTH = 14

const PLANS = {
  ENTERPRISE: 1,
  PROFESSIONAL: 2,
  BASIC: 3
}

const PAID_OR_TRIAL = {
  TRIAL: 'trial',
  PAID_PLAN: 'paidPlan',
  WITHOUT: 'without'
}

class SignUpPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      paidOrTrial: PAID_OR_TRIAL.TRIAL,
      trialEndTime: moment().utc().add(FREE_TRIAL_LENGTH, 'day').toDate(),
      paidPlanEndTime: moment().utc().add(1, 'year').toDate(),
      allowOnlyYearlySubscription: false
    }

    this.handleSubmitInvite = this.handleSubmitInvite.bind(this)
  }

  async handleSubmitInvite (e) {
    e.preventDefault()
    this.setState({ link: null })
    const { paidOrTrial, trialEndTime, paidPlanEndTime, idPlan, allowOnlyYearlySubscription } = this.state

    const email = e.target.elements[0].value
    if (!email || !idPlan) {
      return
    }

    const adjustedTrialEndTime = paidOrTrial === PAID_OR_TRIAL.TRIAL ? moment(trialEndTime).toISOString() : null
    const adjustedPaidPlanEndTime = paidOrTrial === PAID_OR_TRIAL.PAID_PLAN ? moment(paidPlanEndTime).toISOString() : null
    try {
      const response = await getInviteLink({ email, trialEndTime: adjustedTrialEndTime, paidPlanEndTime: adjustedPaidPlanEndTime, idPlan, allowOnlyYearlySubscription })
      if (response.validationUrl) {
        this.setState({ link: response.validationUrl })
      } else {
        window.alert(`${email} is already an active user`)
      }
    } catch (e) {
      window.alert(`Failed to create invite link: ${email}\n\n(${e.message})`)
    }
  }

  onPaidOrTrialChanged (paidOrTrial) {
    this.setState({ paidOrTrial })
  }

  onPlanChanges (idPlan) {
    this.setState({ idPlan })
  }

  onAllowOnlyYearlySubscriptionChanged (allowOnlyYearlySubscription) {
    this.setState({ allowOnlyYearlySubscription })
  }

  onTrialEndTimeChanged (trialEndTime) {
    this.setState({ trialEndTime })
  }

  onPaidPlanEndTimeChanged (paidPlanEndTime) {
    this.setState({ paidPlanEndTime })
  }

  render () {
    const { link, paidOrTrial, trialEndTime, paidPlanEndTime, idPlan, allowOnlyYearlySubscription } = this.state

    return (
      (
        <div {...CSS.page}>
          <Helmet>
            <title>Torii | Backoffice</title>
          </Helmet>
          <Container {...CSS.main}>
            <form onSubmit={this.handleSubmitInvite}>
              <h1>Get invite/sign-up link</h1>
              <input type='email' placeholder='Work email (to get link)' />

              <br />

              <h3>Trial/Paid:</h3>
              <div className='radio'>
                <label>
                  <input type='radio' value={PAID_OR_TRIAL.TRIAL} checked={paidOrTrial === PAID_OR_TRIAL.TRIAL} onChange={() => this.onPaidOrTrialChanged(PAID_OR_TRIAL.TRIAL)} />
                  <span>Trial ends at: </span>
                  <DayInput
                    onDayChange={(date) => this.onTrialEndTimeChanged(date)}
                    value={trialEndTime}
                  />
                  <span> ({moment(trialEndTime).endOf('day').diff(moment(), 'days')}  days)</span>
                </label>
              </div>
              <div className='radio'>
                <label>
                  <input type='radio' value={PAID_OR_TRIAL.PAID_PLAN} checked={paidOrTrial === PAID_OR_TRIAL.PAID_PLAN} onChange={() => this.onPaidOrTrialChanged(PAID_OR_TRIAL.PAID_PLAN)} />
                  <span>Paid plan ends at: </span>
                  <DayInput
                    onDayChange={(date) => this.onPaidPlanEndTimeChanged(date)}
                    value={paidPlanEndTime}
                  />
                  <span> ({moment(paidPlanEndTime).endOf('day').diff(moment(), 'days')}  days)</span>
                </label>
              </div>
              <div className='radio'>
                <label>
                  <input type='radio' value={PAID_OR_TRIAL.WITHOUT} checked={paidOrTrial === PAID_OR_TRIAL.WITHOUT} onChange={() => this.onPaidOrTrialChanged(PAID_OR_TRIAL.WITHOUT)} />
                  Without trial
                </label>
              </div>

              <br />

              <h3>Tier:</h3>
              <div className='radio'>
                <label>
                  <input type='radio' value={PLANS.ENTERPRISE} checked={idPlan === PLANS.ENTERPRISE} onChange={() => this.onPlanChanges(PLANS.ENTERPRISE)} />
                  <span>Enterprise</span>
                </label>
              </div>
              <div className='radio'>
                <label>
                  <input type='radio' value={PLANS.PROFESSIONAL} checked={idPlan === PLANS.PROFESSIONAL} onChange={() => this.onPlanChanges(PLANS.PROFESSIONAL)} />
                  <span>Professional</span>
                </label>
              </div>
              <div className='radio'>
                <label>
                  <input type='radio' value={PLANS.BASIC} checked={idPlan === PLANS.BASIC} onChange={() => this.onPlanChanges(PLANS.BASIC)} />
                  <span>Basic</span>
                </label>
              </div>

              {idPlan === PLANS.BASIC && (
                <div>
                  <h5>Basic plan subscription option:</h5>
                  <div className='radio'>
                    <label>
                      <input type='radio' checked={!allowOnlyYearlySubscription} onChange={() => this.onAllowOnlyYearlySubscriptionChanged(false)} />
                      <span>Yearly and monthly</span>
                    </label>
                  </div>
                  <div className='radio'>
                    <label>
                      <input type='radio' checked={allowOnlyYearlySubscription} onChange={() => this.onAllowOnlyYearlySubscriptionChanged(true)} />
                      <span>Yearly only</span>
                    </label>
                  </div>
                </div>
              )}

              <p>
                <button>Create link</button>
                <br />
                <div {...CSS.explanation}>(This will <strong>NOT</strong> send an email to the email you choose)</div>
                <br />
                {link && (
                  <div>
                    Generated link:
                    <br />
                    <input value={link} readOnly style={{ width: '100%' }} />
                  </div>
                )}
              </p>
            </form>
          </Container>
        </div>
      )
    )
  }
}

export default SignUpPage
