const useProxyForAPIRequests = true
const config = {
  development: {
    apiBaseUrl: 'http://localhost:8001'
  },
  staging: {},
  production: {
    apiBaseUrl: useProxyForAPIRequests ? 'https://backoffice.toriihq.com' : 'https://api.toriihq.com'
  }
}

module.exports = config[process.env.TORII_ENV || process.env.NODE_ENV || 'development']
