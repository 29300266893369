import 'glamor/reset'
import { css } from 'glamor'

css.global('*', {
  boxSizing: 'border-box'
})
css.global('button:focus', {
  outline: 0
})
css.global('a, button', {
  cursor: 'pointer'
})
css.global('a', {
  textDecoration: 'none',
  opacity: 1,
  transition: 'opacity .2s'
})
css.global('a:hover', {
  opacity: 0.9
})
css.global('img', {
  maxWidth: '100%'
})
