import jsonFetch from './json-fetch'
import config from '../config'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'

export const getInviteLink = ({ email, trialEndTime, paidPlanEndTime, idPlan, allowOnlyYearlySubscription }) => {
  const path = '/api/admin/inviteLink'
  const data = omitBy({ email, trialEndTime, paidPlanEndTime, idPlan, allowOnlyYearlySubscription }, isNil)

  return jsonFetch({
    url: `${config.apiBaseUrl}${path}`,
    method: 'POST',
    data
  })
}
